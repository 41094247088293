<template>
  <div class="wrapper -conservas padding-menu -fondo-campo">
    <div class="breadcrumb">
      <div class="container row-end">
        <router-link to="/">Grupo IAN</router-link>
        <router-link :to="'/' + $t('paths.productos')">{{
          $t("navbar_menu.products.title")
        }}</router-link>
        <router-link
          :to="
            '/' + $t('paths.ficha-categoria-producto') + '/' + $route.params.id
          "
        >
          {{ $t(`categorias_productos["${id}"]`).name }}
        </router-link>
      </div>
    </div>
    <section class="intro-page -product">
      <div class="container">
        <h1>{{ $t('categorias_productos["' + id + '"]').name }}</h1>
        <img
          :src="
            `/img/productos/${$t(`categorias_productos['${id}']`).id}/intro.jpg`
          "
          :alt="$t(`categorias_productos[&quot;${id}&quot;]`).name"
        />
        <div
          class="green-box"
          v-html="$t(`categorias_productos['${id}']`).descripcion"
        ></div>
      </div>
    </section>

    <section class="accesos-categorias-productos">
      <div class="container row-start">
        <article
          :style="
            `background:url(/img/productos/${
              $t(`categorias_productos[&quot;${id}&quot;]`).id
            }/acceso-categoria-${n.id}.jpg) center/cover no-repeat`
          "
          class="acceso-categoria"
          :key="key"
          v-for="(n, key) in $t(`categorias_productos['${id}']`).productos"
        >
          <h2>{{ n.name }}</h2>
          <router-link
            :to="`/${$t('paths.ficha-producto')}/${id}/${key}`"
            class="enlace-cover"
          ></router-link>
        </article>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: "quienes-somos",
  props: ["id"],
  mounted() {
    console.log(this.$route.params.id)
    document.title = this.$t(`categorias_productos.${this.$route.params.id}`).seo.title;
    document.head.querySelector("[name=title]").content = this.$t(`categorias_productos.${this.$route.params.id}`).seo.title;
    document.head.querySelector("[name=description]").content = this.$t(`categorias_productos.${this.$route.params.id}`).seo.description;
  },
  
};
</script>
